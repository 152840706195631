<template>
    <div>
        <div style="float:right">
            <el-button type="text" round @click.native="enterAddBanner()" style="font-weight:bold">添加轮播图</el-button>
        </div>
            <el-table
            :data="tableData"
            style="width: 100%">
                <el-table-column
                v-if="false"
                prop="id"
                label="ID"
                width="60">
                </el-table-column>

                <el-table-column
                prop="number"
                label="序号"
                width="60">
                </el-table-column>

                <el-table-column
                prop="imgpath"
                align="center"
                width="400"
                label="图片">
                    <template slot-scope="scope">
                        <el-image
                            :src="scope.row.imgpath"
                            fit="cover"
                            :preview-src-list="scope.row.imgpath"
                            style="width: 400px; height: 100px"
                        ></el-image>
                    </template>
                </el-table-column>

                <el-table-column
                prop="type"
                align="center"
                width="150"
                label="类型">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.type == 0 ? 'info' : 'warning'"
                        disable-transitions>{{scope.row.type == 0 ? '普通':'跳转小程序'}}</el-tag>
                </template>
                </el-table-column>
                
                <el-table-column
                prop="status"
                align="center"
                label="状态"
                width="80">
                    <template slot-scope="scope">
                        <el-tag
                        :type="scope.row.status == true ? 'success' : 'primary'"
                        disable-transitions>{{scope.row.status == true ? '显示':'隐藏'}}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                prop="description"
                align="center"
                width="150"
                label="描述">
                </el-table-column>

                <el-table-column
                prop="create_time"
                align="center"
                width="150"
                label="创建时间">
                </el-table-column>

                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="showEditDialog(scope.row.id)">编辑</el-button>
                        <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 编辑视图 -->
            <el-dialog title="修改轮播图" width="950px" :visible.sync="editWindowVisible" append-to-body @close="editDialogClosed">
                <el-form label-width="140px"  :model="editForm" ref="editFormRef" >
                    <el-form-item label="序号" prop="number">
                        <el-input v-model="editForm.number" style="width: 50%;" type="number"></el-input>
                        <div class="tip">序号越小，排列越前</div>
                    </el-form-item>
                    <el-form-item label="轮播图图片" prop="imgpath" >
                        <el-upload
                            class="avatar-uploader"
                            :action="this.uploadURL"
                            :show-file-list="false"
                            :headers="headers"
                            accept=".jpg , .png"
                            :on-progress="handleUploading"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="editForm.imgpath" :src="editForm.imgpath" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="tip">推荐上传4:1比例的图片，点击更新图片</div>
                
                    </el-form-item>
                    <el-form-item label="描述信息" prop="description">
                        <el-input v-model="editForm.description"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio v-model="editForm.status" :label="true" value="true">显示</el-radio>
                        <el-radio v-model="editForm.status" :label="false" value="false">隐藏</el-radio>
                    </el-form-item>
                    <el-form-item label="类型" prop="type">
                        <el-radio v-model="editForm.type" :label="0" >普通</el-radio>
                        <el-radio v-model="editForm.type" :label="1" >跳转其他小程序</el-radio>
                    </el-form-item>
                    <el-form-item label="小程序APPID" prop="appid" v-if="editForm.type == 1">
                        <el-input v-model="editForm.appid"></el-input>
                    </el-form-item>
                    <el-form-item label="打开小程序路径" prop="appletPath" v-if="editForm.type == 1">
                        <el-input v-model="editForm.appletPath" style="width:50%"></el-input>
                        <div class="tip">默认路径：/pages/index/index</div>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitEditForm">提交</el-button>
                    <el-button @click="editWindowVisible = false">取消</el-button>
                </span>
            </el-dialog>

            <!-- 分页视图 -->
            <div class="block" style="text-align: center;margin-top: 15px;">
                <el-pagination
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handlePageChange"
                    :total="total">
                </el-pagination>
            </div>
    </div>
</template>
<script>
    import {getListByPage,deleteBanner,getOne,updateBanner} from '@/api/banner.js'
    import request from '../../utils/request'
    export default {
        name: 'banner',
        data(){
            return {
                uploadURL:'',
                //修改表单的数据项
                editForm: {
                    imgpath:'',
                    description:'',
                    appid:'',
                    appletPath:''
                },
                //修改表单的验证规则对象
                editFormRules:{
                    title:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请输入分类名称',trigger:'blur'
                        } 
                    ],
                    status:[
                        //required为true代表不为为空，trigger代表触发时机，blur代表当聚焦时
                        {
                            required:true,message:'请选择状态',trigger:'blur'
                        } 
                    ]
                },
                //控制修改对话框的显示与隐藏
                editWindowVisible: false,
                addOperate: true,
                //页大小
                pageSize: 5,
                //当前页
                currentPage: 1,
                //表格数据总和
                total: 0,
                //表格数据列表
                tableData: [],
                headers:{
                    token:""
                }
            }
            
        },
        methods:{
            //当页数发生改变时，触发的函数
            handlePageChange(val){
                var that = this;
                getListByPage(`${val}`,this.pageSize).then(res=>{
                    that.total = res.data.total
                    that.tableData = res.data.data;
                })
                
            },
            //进入添加轮播图
            enterAddBanner(){
                this.$router.push({path:'/addBanner'})
            },
            //获取轮播图信息
            getTableData(){
                var that = this;
                getListByPage(this.currentPage,this.pageSize).then(res=>{
                    that.total = res.data.total
                    that.tableData = res.data.data;
                }).catch(error=>{
                    that.$message({
                            message: "请求数据失败",
                            type: 'error',
                            duration: 1500
                        });
                })
            },
            //监听修改表单的关闭事件
            editDialogClosed(){
                this.$refs.editFormRef.resetFields();
            },
            //点击编辑事件函数
            showEditDialog(id){
                var that = this;
                getOne(id).then(res=>{
                    console.log("editres:");
                    console.log(res)
                    that.editForm = res.data.data
                })
                this.editWindowVisible = true;
            },
            //根据ID删除壁纸分类
            handleDelete(id){
                var that = this;
                deleteBanner(id).then(res=>{
                    if(res.data.status){
                        this.$message({
                            message: res.data.msg,
                            type: 'success',
                            duration: 1000
                        });
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error',
                            duration: 1000
                        });
                    }
                    //刷新表格数据
                    that.getTableData();
                })
            },
            
            //提交修改表单触发事件
            submitEditForm(){
                var that = this;
                if(this.editForm.imgpath == ""){
                    this.$message.error('请上传轮播图图片');
                    return;
                }
                console.log(this.editForm.type)
                console.log(this.editForm.appid)
                console.log(this.editForm.appletPath)
                if(this.editForm.type == "1" &&  (this.editForm.appid == null || this.editForm.appid == '')){
                    this.$message.error('请输入小程序APPID');
                    return;
                }

                if(this.editForm.type == "1" &&  (this.editForm.appletPath == null || this.editForm.appletPath == '')){
                    this.$message.error('请输入小程序页面路径');
                    return;
                }
                updateBanner(this.editForm).then(res=>{
                    this.$message.success('修改成功');
                    this.getTableData();
                    this.editWindowVisible = false;
                }).catch(error=>{
                    that.$message({
                        message: "请求失败",
                        type: 'error',
                        duration: 1500
                    });
                })
            },

            //上传轮播图成功后的回调函数
            handleAvatarSuccess(res, file) {
                this.loading.close()
                this.editForm.imgpath = res.data;
                this.$message.success('上传成功');
            },   
            //上传轮播图之前的处理函数
            beforeAvatarUpload(file) {
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                this.$message.error('上传头像图片大小不能超过 20MB!');
                }
                return isLt20M;
            },
            handleUploading(event, file, fileList){
                 //显示加载动画
                this.loading = this.$loading({
                    lock: true,
                    text: '文件上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
            },
        },
        mounted(){
            this.getTableData();
            //获取接口基地址
            this.uploadURL = request.defaults.baseURL + "uploadImg";
            //获取当前的token
            var token = localStorage.getItem("token");
            this.headers.token = token;
        }
    }
</script>

<style>
    .around{
        display: flex; 
        align-items: center;
        justify-content: center;
        width: 32%;
        height: 100px;
    }
    .white{
        color: white;
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 720px;
    height: 180px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width:  720px;
    height: 180px;
    display: block;
  }
</style>